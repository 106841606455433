import { cloneElement } from "react";
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
} from "react-admin";

const PlanListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton />
      {/* Add your custom actions */}
    </TopToolbar>
  );
};

export default PlanListActions;
