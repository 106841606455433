import React, { useState } from "react";
import { Button as RAButton } from "react-admin";
import { Tooltip } from "@material-ui/core";
import { useProfileContext } from "../../contexts/ProfileContext";
import InstagramAuthDialog from "../InstagramAuthDialog";
import InstagramImportDialog from "../InstagramImportDialog";
import InstagramIcon from "../../assets/icons/InstagramIcon";

const ImportInstagramButton = () => {
  const [instagramAuthDialogOpen, setInstagramAuthDialogOpen] = useState(false);
  const [instagramImportDialogOpen, setInstagramImportDialogOpen] = useState(
    false
  );
  const [instagramAuthInfo, setInstagramAuthInfo] = useState<any>({});
  const { profile } = useProfileContext();
  const today = new Date();

  const handleImportInstagramFlow = () => {
    if (profile.instagramAuth && profile.instagramAuth.access_token && profile.instagramAuth.expire_by_seconds > today) {
      console.log("Instagram Already connected. Skip authentication");
      setInstagramAuthInfo(profile.instagramAuth);
      setInstagramAuthDialogOpen(false);
      setInstagramImportDialogOpen(true);
    } else {
      console.log("Instagram to be connected");
      setInstagramAuthDialogOpen(true);
    }
  };

  return (
    <>
      <Tooltip title="Import from Instagram">
        <div>
          <RAButton
            color="primary"
            component="span"
            variant="text"
            label="Import from Instagram"
            onClick={() => handleImportInstagramFlow()}
          >
            <InstagramIcon style={{ fontSize: "24" }} />
          </RAButton>
        </div>
      </Tooltip>
      <InstagramAuthDialog
        open={instagramAuthDialogOpen}
        onAuthenticated={(auth) => {
          setInstagramAuthInfo(auth);
          setInstagramAuthDialogOpen(false);
          setInstagramImportDialogOpen(true);
        }}
        isLoading={false}
        handleClose={() => setInstagramAuthDialogOpen(false)}
      />
      <InstagramImportDialog
        open={instagramImportDialogOpen}
        token={instagramAuthInfo?.access_token}
        userId={instagramAuthInfo?.user_id}
        handleClose={() => setInstagramImportDialogOpen(false)}
      />
    </>
  );
};

export default ImportInstagramButton;
