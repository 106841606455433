import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  BooleanInput,
  required,
  maxLength,
  TopToolbar,
  ListButton,
  useRecordContext,
  FormGroupContextProvider,
  useDataProvider,
  useFormGroup,
  useNotify,
  ImageInput,
  ImageField,
} from "react-admin";
import franc from "franc-min";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import tableIcons from "../../assets/icons/MaterialTableIcons";
import MediaField from "../../components/MediaField";
import ImportPhraseDialog from "../../components/ImportPhraseDialog";
import AutocompleteTagInput from "../../components/AutocompleteTagInput";
import {
  FIREBASE_DYNAMIC_LINKS_KEY,
  FIREBASE_DYNAMIC_LINKS_REST_API,
  generateDynamicLinks,
  FIRESTORE_LESSON_COLLECTION,
  FIRESTORE_PHRASE_COLLECTION,
} from "../../config/firebase";
import { get6391LangCode } from "../../config/i18n";
import { instrumentsConfig } from "../../config/params";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/core/styles";
import { convertObjectRelativePath } from "../../utils";

const useStyles = makeStyles({
  topToolbar: {
    justifyContent: "flex-start",
  },
});

const PostEditActions = ({ basePath }: { basePath: any }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.topToolbar}>
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    </TopToolbar>
  );
};

const PhraseTable = ({ props }: { props: any }) => {
  const lessonId = props.id;
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const history = useHistory();
  const formGroupState = useFormGroup("overview");
  const notify = useNotify();
  const [phrases, setPhrases] = useState([]);
  const [openImportPhraseDialog, setOpenImportPhraseDialog] = useState(false);
  useEffect(() => {
    if (Array.isArray(record?.phrases)) {
      dataProvider
        .getMany(FIRESTORE_PHRASE_COLLECTION, {
          ids: record?.phrases,
        })
        .then(({ data }: { data: any }) => setPhrases(data));
    }
  }, [record, dataProvider]);
  return (
    <>
      <ImportPhraseDialog
        handleClose={() => setOpenImportPhraseDialog(false)}
        open={openImportPhraseDialog}
        originType={FIRESTORE_LESSON_COLLECTION}
        originId={lessonId}
      />
      <MaterialTable
        title="Phrases"
        columns={[
          { title: "Id", field: "id", width: 150 },
          { title: "Video Id", field: "videoId", width: 150 },
          { title: "Title", field: "title", width: 300 },
          { title: "Description", field: "description", width: 400 },
          { title: "Difficulty", field: "difficulty", width: 100 },
        ]}
        data={phrases}
        icons={tableIcons}
        actions={[
          {
            icon: tableIcons.Edit,
            tooltip: "Edit Phrase",
            onClick: (event, rowData) => {
              if (formGroupState && !formGroupState.pristine) {
                return notify("Please Save first");
              }
              history.push(`/${FIRESTORE_PHRASE_COLLECTION}/${rowData.id}`, {
                originData: {
                  originType: FIRESTORE_LESSON_COLLECTION,
                  originId: lessonId,
                },
              });
            },
          },
          {
            icon: tableIcons.Add,
            tooltip: "Create Phrase",
            isFreeAction: true,
            onClick: () => {
              if (formGroupState && !formGroupState.pristine) {
                return notify("Please Save first");
              }
              history.push(`/${FIRESTORE_PHRASE_COLLECTION}/create`, {
                record: {
                  createdby: record.createdby,
                  createdbyDisplayName: record.createdbyDisplayName,
                  source: record.source,
                  videoId: record.videoId,
                  thumbnailRatio: record.thumbnailRatio,
                  difficulty: record.difficulty,
                  instruments: record.instruments,
                  tags: record.tags,
                },
                originData: {
                  originType: FIRESTORE_LESSON_COLLECTION,
                  originId: lessonId,
                },
              });
            },
          },
          {
            icon: tableIcons.Export,
            tooltip: "Import Phrase",
            isFreeAction: true,
            onClick: () => setOpenImportPhraseDialog(true),
          },
        ]}
        options={{ paging: false, actionsColumnIndex: -1 }}
      />
    </>
  );
};

const LessonEdit = (props: any) => {
  return (
    <Edit
      {...props}
      transform={(_data: any) => {
        const data = convertObjectRelativePath(
          _data,
          FIRESTORE_LESSON_COLLECTION
        );
        const descLang6393 = franc(data.description);
        const descLang6391 = descLang6393 && get6391LangCode(descLang6393);
        data.descLang = descLang6391 || null;
        return data.dynamicLink
          ? data
          : fetchDynamicLinks(FIRESTORE_LESSON_COLLECTION, data).then(
              (res: any) =>
                res?.shortLink ? { ...data, dynamicLink: res?.shortLink } : data
            );
      }}
      actions={<PostEditActions basePath={`/${FIRESTORE_LESSON_COLLECTION}`} />}
      mutationMode="pessimistic"
    >
      <SimpleForm redirect={false}>
        {/* <DisabledInput source="id" /> */}
        <FormGroupContextProvider name="overview">
          <BooleanInput label="Publish" source="published" />
          <MediaField
            label="Video"
            source="rawData"
            type="source"
            videoId="videoId"
            startSeconds="startSeconds"
            endSeconds="endSeconds"
            size="md"
          />
          <ImageInput
            accept=".png, .jpg, .jpeg"
            source="thumbnailFile"
            label="Thumbnail"
            maxSize="1000000"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <div style={{ marginTop: "20px" }} />
          <TextInput
            source="title"
            label="Title"
            validate={maxLength(100)}
            fullWidth
          />
          <SelectInput
            source="difficulty"
            label="Difficulty"
            choices={[
              { id: "beginner", name: "Beginner" },
              { id: "intermediate", name: "Intermediate" },
              { id: "advanced", name: "Advanced" },
            ]}
            fullWidth
          />
          <SelectArrayInput
            label="Instruments"
            source="instruments"
            choices={instrumentsConfig}
            fullWidth
          />
          <TextInput
            source="description"
            label="Description"
            multiline
            fullWidth
            validate={required()}
          />
          <AutocompleteTagInput />
        </FormGroupContextProvider>
        <PhraseTable props={props} />
      </SimpleForm>
    </Edit>
  );
};

const fetchDynamicLinks = async (contentType: string, data: any) => {
  const dynamicLinkMetaData = await generateDynamicLinks(contentType, data);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dynamicLinkMetaData),
  };
  const dynamicLink = await fetch(
    `${FIREBASE_DYNAMIC_LINKS_REST_API}${FIREBASE_DYNAMIC_LINKS_KEY}`,
    options
  );
  const dynamicLinkRes = await dynamicLink.json();
  console.log(dynamicLinkRes);
  return dynamicLinkRes;
};

export default LessonEdit;
