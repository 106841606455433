import React from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";
import { useRecordContext } from "react-admin";

const MediaField = (props) => {
  const { source, videoId, type, startSeconds, endSeconds, size } = props;
  const record = useRecordContext(props);
  const videoStyles = {
    sm: { width: "150px", height: "150px" },
    md: { width: "300px", height: "300px" },
    lg: { width: "450px", height: "450px" },
  };

  const ytbSize = {
    sm: { width: 150, height: 150 },
    md: { width: 300, height: 300 },
    lg: { width: 450, height: 450 },
  };

  const generateYtbConfig = (params) => {
    const { size, startSeconds, endSeconds } = params;
    const sizeConfig = ytbSize[size] || ytbSize.sm;
    return {
      ...sizeConfig,
      playerVars: { start: startSeconds, end: endSeconds },
    };
  };

  switch (record[type]) {
    case "instagram":
      return (
        <video
          style={videoStyles[size] || videoStyles.sm}
          preload="none"
          poster={record[source]?.thumbnail_url}
          controls
        >
          <source src={record[source]?.media_url} type="video/mp4"></source>
        </video>
      );
    case "youtube":
      return (
        <YouTube
          videoId={record[videoId]}
          opts={generateYtbConfig({
            size,
            startSeconds: record[startSeconds],
            endSeconds: record[endSeconds],
          })}
        />
      );
    default:
      return;
  }
};

MediaField.propTypes = {
  size: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  headerClassName: PropTypes.string,
  cellClassName: PropTypes.string,
};

export default MediaField;
