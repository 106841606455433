import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  FileField,
  FileInput,
  required,
  maxLength,
  NumberInput,
  TopToolbar,
  Button,
  SaveButton,
  Toolbar,
  useDataProvider,
  useRedirect,
  useRecordContext,
  useNotify,
} from "react-admin";
import { useHistory } from "react-router-dom";
import MediaField from "../../components/MediaField";
import AutocompleteTagInput from "../../components/AutocompleteTagInput";
import {
  FIRESTORE_PHRASE_COLLECTION,
  FIRESTORE_LESSON_COLLECTION,
  FIRESTORE_PLAN_COLLECTION,
} from "../../config/firebase";
import { hmsToSeconds } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  topToolbar: {
    justifyContent: "flex-start",
  },
});

const PhraseToolbar = (props: any) => {
  const { originId, originType } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const history = useHistory();
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Save"
        redirect={false}
        submitOnEnter={false}
        onSuccess={async ({ data }: { data: any }) => {
          if (originType === FIRESTORE_LESSON_COLLECTION) {
            await dataProvider.addPhraseToLesson({
              phraseId: data.id,
              lessonId: originId,
            });
            //TODO: below is not working yet
            // } else if (originType === FIRESTORE_PLAN_COLLECTION) {
            //   await dataProvider.addPhraseToPlanSchedule({
            //     phraseId: data.id,
            //     planId: originId,
            //     originPlanData: originDocData,
            //     schedulePath: pathInDoc,
            //   });
          }
          notify("Created phrase");
          history.push(`/${FIRESTORE_PHRASE_COLLECTION}/${data.id}`, {
            originData: { originType, originId },
          });
        }}
      />
    </Toolbar>
  );
};

const PostEditActions = () => {
  const classes = useStyles();
  const { originId, originType } = useRecordContext();
  const redirect = useRedirect();
  return (
    <TopToolbar className={classes.topToolbar}>
      <Button
        label="< Return to Lesson"
        onClick={() => redirect(`/${originType}/${originId}`)}
      />
    </TopToolbar>
  );
};

const PhraseCreate = (props: any) => {
  const originData = props?.location?.state?.originData;
  const originType = originData?.originType || null;
  const originId = originData?.originId || null;
  return (
    <Create {...props} actions={<PostEditActions />} mutationMode="pessimistic">
      <SimpleForm
        toolbar={<PhraseToolbar originType={originType} originId={originId} />}
      >
        {/* <DisabledInput source="id" /> */}
        <TextInput source="videoId" label="Video Id" validate={required()} />
        <MediaField
          label="Video"
          source="rawData"
          type="source"
          videoId="videoId"
          startSeconds="startSeconds"
          endSeconds="endSeconds"
          size="md"
        />
        <TextInput
          source="startSeconds"
          label="Start Seconds"
          validate={required()}
          parse={hmsToSeconds}
        />
        <TextInput
          source="endSeconds"
          label="End Seconds"
          validate={required()}
          parse={hmsToSeconds}
        />
        <div style={{ marginTop: "20px" }} />
        <TextInput
          source="title"
          label="Title"
          validate={maxLength(100)}
          fullWidth
        />
        <SelectInput
          source="difficulty"
          label="Difficulty"
          choices={[
            { id: "beginner", name: "Beginner" },
            { id: "intermediate", name: "Intermediate" },
            { id: "advanced", name: "Advanced" },
          ]}
          fullWidth
        />
        <SelectArrayInput
          label="Instruments"
          source="instruments"
          choices={[
            { id: "acousticGuitar", name: "Acoustic Guitar" },
            { id: "electricGuitar", name: "Electric Guitar" },
          ]}
          fullWidth
        />
        <TextInput
          source="description"
          label="Description"
          multiline
          fullWidth
        />
        <AutocompleteTagInput />
        <FileInput
          source="file"
          label="Tab File (Guitar Pro formats)"
          accept=".gp,.gpx,.gp5,.gp4,.gp3"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default PhraseCreate;
