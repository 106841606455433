export const getQueryString = (queryName, queryString) => {
  if (typeof queryString !== "string") {
    return null;
  }
  let reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
  let r = queryString.substr(1).match(reg);
  if (r != null) return r[2];
  return null;
};

export const removeHashtags = (str) => {
  const regexp = new RegExp("#([^\\s]*)", "g");
  return str.replace(regexp, "").replace(/  +/g, " ");
};

export const instagramDataImportPipeline = (data) => {
  if (!Array.isArray(data)) return [];
  return (
    data
      //Flatten children
      .flatMap((post) => {
        if (post.media_type !== "CAROUSEL_ALBUM") return post;
        return post.children.data.map((child) => ({
          ...child,
          caption: post.caption,
        }));
      })
      //Filter out IMAGE
      .filter((post) => post.media_type === "VIDEO")
      //Remove Hash tags in the caption
      .map((post) =>
        post.caption
          ? {
              ...post,
              caption: removeHashtags(post.caption),
            }
          : post
      )
  );
};

export const convertObjectRelativePath = (data: any, prefixPath: string) => {
  if (!prefixPath || prefixPath === "") {
    return data;
  }
  for (var key in data) {
    if (key === "tabs") {
      for (let tab of data[key]) {
        if (tab?.file?.src && typeof tab?.file?.src === "string") {
          tab.file.src = convertUrlRelativePath(
            tab.file.src,
            prefixPath,
            tab.file.title
          );
        }
      }
    }
    if (data[key]?.src && typeof data[key].src === "string") {
      data[key].src = convertUrlRelativePath(
        data[key].src,
        prefixPath,
        data[key].title
      );
    }
  }
  return data;
};

export const convertUrlRelativePath = (
  url: string,
  prefixPath: string,
  filename: string
) => {
  if (url.indexOf("https://firebasestorage.googleapis.com/") > -1) {
    url = url.replace(/%2F/g, "/").split("?")[0];
    var split = url.split("/");
    let gotPrefix = false;
    for (let i = 0; i < split.length; i++) {
      if (i === split.length - 1) {
        split[i] = filename;
      }
      if (split[i] === prefixPath) {
        url = split[i];
        gotPrefix = true;
      } else if (gotPrefix) {
        url += `/${split[i]}`;
      }
    }
    url = url.replace(/%20/g, " ");
  }
  return url;
};

export const parseVideoURL = (url) => {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  //   - https://m.youtube.com/watch?v=My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(
    /(http:|https:|)\/\/(player.|www.|m.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\?t=[0-9]+)?(\&\S+)?/
  );
  let type = null;
  if (RegExp.$3.indexOf("youtu") > -1) {
    type = "youtube";
  } else if (RegExp.$3.indexOf("vimeo") > -1) {
    type = "vimeo";
  }
  let startSeconds = null;
  if (RegExp.$7) {
    startSeconds = parseInt(RegExp.$7.split("=")[1]);
  }
  return {
    type: type,
    id: RegExp.$6,
    startSeconds,
  };
};

// Convert time format from hms to seconds
export const hmsToSeconds = (hms) => {
  if (!isNaN(hms)) return hms;
  let p = hms.split(":"),
    s = 0,
    m = 1;
  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }
  return s;
};

// Convert time format from seconds to hms
export const secondsToHms = (sec) => {
  if (isNaN(sec) || sec < 0) return "0:00";
  return sec < 3600
    ? new Date(sec * 1000).toISOString().substr(14, 5)
    : new Date(sec * 1000).toISOString().substr(11, 8);
};
