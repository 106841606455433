import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

const ImportDialogWrapper = (props: {
  open: boolean;
  title?: string | undefined;
  subTitle?: string | undefined;
  handleClose: () => any;
  children?: any;
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {props.title && (
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      )}
      <DialogContent>
        {props.subTitle && (
          <div>
            <p
              style={{
                fontFamily: "sans-serif",
                margin: "0",
                fontSize: "0.9em",
                marginBottom: "10px",
                marginTop: "-7px",
                color: "#555",
              }}
            >
              {props.subTitle}
            </p>
          </div>
        )}
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default ImportDialogWrapper;
