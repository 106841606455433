import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

export const firebaseConfig = {
  apiKey: "AIzaSyAL-SOeXB0bvNbqI9ZI26eW8AxD2T8vWsg",
  authDomain: "guitar-practice-app.firebaseapp.com",
  databaseURL: "https://guitar-practice-app.firebaseio.com",
  projectId: "guitar-practice-app",
  storageBucket: "guitar-practice-app.appspot.com",
  messagingSenderId: "154044727249",
  appId: "1:154044727249:web:13ceccf1f8c683ce38aaf0",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const storageGsURI = `gs://guitar-practice-app.appspot.com/`;
export const db = firebase.firestore();
// if (process.env.NODE_ENV !== "production") {
//   db.useEmulator("localhost", 8080);
// }
export const fbStorage = firebase.storage();
export const functions = firebase.functions();
// functions.useEmulator("127.0.0.1", 5001);
export const FIRESTORE_LESSON_COLLECTION = "lesson";
export const FIRESTORE_PHRASE_COLLECTION = "phrase";
export const FIRESTORE_PLAN_COLLECTION = "plan";
export const FIRESTORE_TAG_COLLECTION = "tag";
export const FIRESTORE_USER_COLLECTION = "users";
export const FIREBASE_DYNAMIC_LINKS_KEY =
  "AIzaSyAjp4OUberVxTtNHkpmqV0e3Q69FNBioGY";
export const FIREBASE_DYNAMIC_LINKS_REST_API =
  "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=";

export const fetchGsFileUri = (path) => {
  const url = storageGsURI + path;
  const gsReference = fbStorage.refFromURL(url);
  return gsReference
    .getDownloadURL()
    .catch((e) => console.error("file doesn't exist"));
};

//TODO: Refactor the following. Move the generator to list page and generate dynamicLink only when instructed
//https://firebase.google.com/docs/reference/dynamic-links/link-shortener
export const generateDynamicLinks = async (contentType, data) => {
  const { id: tabId, title, thumbnailFile } = data;
  const thumbnailUrl = thumbnailFile?.src
    ? await fetchGsFileUri(thumbnailFile.src)
    : null;
  return {
    dynamicLinkInfo: {
      domainUriPrefix: "https://guitarpractice.page.link",
      link: `https://guitarpracticeapp.com/${contentType}/${tabId}`,
      androidInfo: {
        androidPackageName: "io.cresc.guitarpractice",
      },
      iosInfo: {
        iosBundleId: "io.cresc.guitarpractice",
        iosAppStoreId: "1546598754"
      },
      socialMetaTagInfo: {
        socialTitle: title,
        // socialDescription: "Tab OPEN to check out!",
        socialImageLink: thumbnailUrl,
      },
    },
  };
};

export default firebase;
