import React, { useState, useCallback } from "react";
import NewWindow from "react-new-window";
import { makeStyles, createStyles, Grid, Typography } from "@material-ui/core";
import { useNotify, useRefresh, Button as RAButton } from "react-admin";
import ImportDialogWrapper from "../ImportDialogWrapper";
import Loader from "../Loader";
import { useProfileContext } from "../../contexts/ProfileContext";
import InstagramIcon from "../../assets/icons/InstagramIcon";
import { INSTAGRAM_AUTH_URI } from "../../config/constants";
import { getQueryString } from "../../utils";
import { instagramAuthHandler } from "../../utils/firebase";

interface InstagramAuthDialogProps {
  handleClose: () => any;
  open: boolean;
  isLoading: boolean;
  onAuthenticated: (auth: object) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    authDialogWrapper: {
      padding: theme.spacing(4),
    },
    continueButton: {
      margin: theme.spacing(2, 2.5),
      display: "flex",
    },
  })
);

const InstagramAuthDialog = (props: InstagramAuthDialogProps) => {
  const { handleClose, open, isLoading, onAuthenticated } = props;
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [newWindowOpen, setNewWindowOpen] = useState(false);
  const [connectingInstagram, setConnectingInstagram] = useState(false);
  const { getProfile } = useProfileContext();

  const handleInstagramAuth = useCallback(
    (data: any) => {
      const code = getQueryString("code", data);
      const error = getQueryString("error", data);
      if (code) {
        // get the URL params and redirect to our server to use Passport to auth/login
        instagramAuthHandler(code)
          .then((res) => {
            setConnectingInstagram(false);
            onAuthenticated(res?.data);
            notify("Instagram account connected");
          })
          //Get the latest profile
          .then(() => getProfile())
          .then(() => refresh())
          .catch((err) => {
            setConnectingInstagram(false);
            console.log(err);
            notify(
              "Couldn't connect to Instagram account. Please try again or contact us: support@cresc.io",
              "warning"
            );
          });
      } else if (error) {
        setConnectingInstagram(false);
        console.log(error);
        notify(
          "Couldn't connect to Instagram account. Please try again or contact us: support@cresc.io",
          "warning"
        );
      } else {
        setConnectingInstagram(false);
        notify(
          "Couldn't connect to Instagram account. Please try again or contact us: support@cresc.io",
          "warning"
        );
      }
    },
    [onAuthenticated, notify, getProfile, refresh]
  );

  return (
    <ImportDialogWrapper open={open} handleClose={handleClose}>
      {isLoading && <Loader loadingTxt="Please wait..."></Loader>}
      {newWindowOpen && (
        <NewWindow
          url={INSTAGRAM_AUTH_URI}
          onUnload={() => {
            setNewWindowOpen(false);
            const instagramCodeJson = localStorage.getItem("instagramCode");
            if (instagramCodeJson) {
              const instagramCode = JSON.parse(instagramCodeJson);
              handleInstagramAuth(instagramCode?.params);
            }
          }}
          features={{ left: 200, top: 200, width: 400, height: 400 }}
          copyStyles={false}
        />
      )}
      <Grid container justify="center" className={classes.authDialogWrapper}>
        <Grid container direction="column" justify="center">
          {connectingInstagram ? (
            <>
              <Typography align="center" variant="overline" color="inherit">
                Connecting...
              </Typography>
              <Typography align="center" variant="overline" color="inherit">
                Please wait
              </Typography>
            </>
          ) : (
            <>
              <Typography align="center" variant="overline" color="inherit">
                Connect your Instagram account
              </Typography>
              <Typography align="center" variant="overline" color="inherit">
                To start importing data
              </Typography>
            </>
          )}
          <RAButton
            color="primary"
            className={classes.continueButton}
            component="span"
            variant="text"
            label="Connect"
            onClick={() => {
              setConnectingInstagram(true);
              setNewWindowOpen(true);
            }}
          >
            <InstagramIcon />
          </RAButton>
        </Grid>
      </Grid>
    </ImportDialogWrapper>
  );
};

export default InstagramAuthDialog;
