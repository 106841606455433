import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import { Layout, AppBar, UserMenu, MenuItemLink } from "react-admin";
import { ProfileProvider, useProfile } from "../../pages/Profile";

//https://marmelab.com/react-admin/Theming.html#using-a-custom-layout
// const CustomLayout = (props) => <Layout {...props} />;

const MyUserMenu = (props) => {
    const { profileVersion } = useProfile();
  
    return (
      <UserMenu key={profileVersion} {...props}>
        <MenuItemLink
          to="/my-profile"
          primaryText="My Profile"
          leftIcon={<SettingsIcon />}
        />
      </UserMenu>
    );
  };

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;

const CustomLayout = (props) => (
    <ProfileProvider>
      <Layout {...props} appBar={MyAppBar} />
    </ProfileProvider>
  );

export default CustomLayout;
