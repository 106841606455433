import { useState } from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import {
  useNotify,
  useRefresh,
  useDataProvider,
  Loading,
  Button as RAButton,
} from "react-admin";
import ImportDialogWrapper from "../ImportDialogWrapper";
import {
  FIRESTORE_LESSON_COLLECTION,
  FIRESTORE_PLAN_COLLECTION,
} from "../../config/firebase";

interface ImportPhraseDialogProps {
  handleClose: () => any;
  open: boolean;
  originType: string;
  originId: string | null;
  pathInDoc?: string;
  originDocData?: any;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogWrapper: {
      padding: theme.spacing(4),
    },
    continueButton: {
      margin: theme.spacing(2, 2.5),
      display: "flex",
    },
    linkForm: {
      height: "80px",
    },
    linkInput: {
      width: "100%",
    },
    spacer: { padding: theme.spacing(1) },
  })
);

const ImportPhraseDialog = (props: ImportPhraseDialogProps) => {
  const { handleClose, open, originId, originType, pathInDoc, originDocData } =
    props;
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [phraseId, setPhraseId] = useState("");
  const [phraseTopic, setPhraseTopic] = useState("");
  const [practiceMins, setPracticeMins] = useState("10");
  const [showPhraseIdErrorHelper, setShowPhraseIdErrorHelper] = useState(false);
  const [showPhraseTopicErrorHelper, setShowPhraseTopicErrorHelper] =
    useState(false);
  const [showPracticeMinsErrorHelper, setShowPracticeMinsErrorHelper] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  return (
    <ImportDialogWrapper open={open} handleClose={handleClose}>
      {isLoading ? (
        <Loading
          loadingPrimary="Importing..."
          loadingSecondary="Please wait"
          className={classes.dialogWrapper}
        />
      ) : (
        <Grid container justify="center" className={classes.dialogWrapper}>
          <Typography align="center" variant="overline" color="inherit">
            Input phrase ID
          </Typography>
          <Grid container direction="column" justify="center">
            <form noValidate autoComplete="off" className={classes.linkForm}>
              <TextField
                error={showPhraseIdErrorHelper}
                id="youtube-link"
                className={classes.linkInput}
                placeholder="e.g. phj3m6LwqqtJ9VXlSsBW"
                variant="outlined"
                margin="dense"
                value={phraseId}
                onChange={(e) => {
                  setShowPhraseIdErrorHelper(false);
                  setPhraseId(e.target.value);
                }}
                helperText={showPhraseIdErrorHelper ? "Incorrect entry" : null}
              />
            </form>
            {originType === FIRESTORE_PLAN_COLLECTION && (
              <>
                <Typography align="center" variant="overline" color="inherit">
                  Topic
                </Typography>
                <form
                  noValidate
                  autoComplete="off"
                  className={classes.linkForm}
                >
                  <TextField
                    error={showPhraseTopicErrorHelper}
                    id="practice-topic"
                    className={classes.linkInput}
                    placeholder="e.g. scale practices"
                    variant="outlined"
                    margin="dense"
                    value={phraseTopic}
                    onChange={(e) => {
                      setShowPhraseTopicErrorHelper(false);
                      setPhraseTopic(e.target.value);
                    }}
                    helperText={
                      showPhraseTopicErrorHelper ? "Incorrect entry" : null
                    }
                  />
                </form>
                <Typography align="center" variant="overline" color="inherit">
                  How long to practice in mins
                </Typography>
                <form
                  noValidate
                  autoComplete="off"
                  className={classes.linkForm}
                >
                  <TextField
                    error={showPracticeMinsErrorHelper}
                    id="practice-duration"
                    className={classes.linkInput}
                    placeholder="e.g. 10"
                    variant="outlined"
                    margin="dense"
                    value={practiceMins}
                    onChange={(e) => {
                      setShowPracticeMinsErrorHelper(false);
                      setPracticeMins(e.target.value);
                    }}
                    helperText={
                      showPracticeMinsErrorHelper
                        ? "Input must be Integral"
                        : null
                    }
                  />
                </form>
              </>
            )}
            <RAButton
              color="primary"
              className={classes.continueButton}
              component="span"
              variant="text"
              label="Add"
              onClick={async () => {
                if (!phraseId) return setShowPhraseIdErrorHelper(true);
                const practiceMinsInt = parseInt(practiceMins);
                if (originType === FIRESTORE_PLAN_COLLECTION) {
                  if (!phraseTopic) return setShowPhraseTopicErrorHelper(true);
                  if (isNaN(practiceMinsInt))
                    return setShowPracticeMinsErrorHelper(true);
                }
                setIsLoading(true);
                let result;
                try {
                  if (originType === FIRESTORE_LESSON_COLLECTION) {
                    result = await dataProvider.addPhraseToLesson({
                      phraseId,
                      lessonId: originId,
                    });
                  } else if (originType === FIRESTORE_PLAN_COLLECTION) {
                    result = await dataProvider.addPhraseToPlanSchedule({
                      phraseId,
                      topic: phraseTopic,
                      practiceMins: practiceMinsInt,
                      planId: originId,
                      originalPlanData: originDocData,
                      schedulePath: pathInDoc,
                    });
                  }
                  if (result.data.isDone === false) {
                    notify(result.data.error);
                    setIsLoading(false);
                  }
                  refresh();
                  setIsLoading(false);
                  notify("Import successful");
                  handleClose();
                } catch (err: any) {
                  console.log(err);
                  setIsLoading(false);
                  notify(
                    "Failed to add the phrase. Please confirm if the phrase ID is valid"
                  );
                }
              }}
            ></RAButton>
          </Grid>
        </Grid>
      )}
    </ImportDialogWrapper>
  );
};

export default ImportPhraseDialog;
