import React, { useState } from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import {
  useNotify,
  useRedirect,
  useRefresh,
  useGetIdentity,
  Loading,
  Button as RAButton,
} from "react-admin";
import ImportDialogWrapper from "../ImportDialogWrapper";
import YoutubeIcon from "../../assets/icons/YoutubeIcon";
import { FIRESTORE_LESSON_COLLECTION } from "../../config/firebase";
import { parseVideoURL } from "../../utils";
import { addYoutubeVideoHandler } from "../../utils/firebase";

interface YoutubeImportDialogProps {
  handleClose: () => any;
  open: boolean;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogWrapper: {
      height: "300px",
      padding: theme.spacing(4),
    },
    continueButton: {
      margin: theme.spacing(2, 2.5),
      display: "flex",
    },
    linkForm: {
      height: "80px",
    },
    linkInput: {
      width: "100%",
    },
    spacer: { padding: theme.spacing(1) },
  })
);

const YoutubeImportDialog = (props: YoutubeImportDialogProps) => {
  const { handleClose, open } = props;
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { identity, loading: identityLoading } = useGetIdentity();
  const [link, setLink] = useState("");
  const [showErrorHelper, setShowErrorHelper] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  return (
    <ImportDialogWrapper open={open} handleClose={handleClose}>
      {isLoading ? (
        <Loading
          loadingPrimary="Importing..."
          loadingSecondary="Please wait"
          className={classes.dialogWrapper}
        />
      ) : (
        <Grid container justify="center" className={classes.dialogWrapper}>
          <Grid container direction="column" justify="center">
            <Typography align="center" variant="overline" color="inherit">
              Add YouTube Shared Link
            </Typography>
            <Grid item className={classes.spacer} />
            <form noValidate autoComplete="off" className={classes.linkForm}>
              <TextField
                error={showErrorHelper}
                id="youtube-link"
                className={classes.linkInput}
                placeholder="http://youtu.be/My2FRPA3Gf8"
                variant="outlined"
                value={link}
                onChange={(e) => {
                  setShowErrorHelper(false);
                  setLink(e.target.value);
                }}
                helperText={showErrorHelper ? "Incorrect entry" : null}
              />
            </form>
            <RAButton
              color="primary"
              className={classes.continueButton}
              component="span"
              variant="text"
              label="Add"
              onClick={() => {
                const video = parseVideoURL(link);
                if (video.type !== "youtube") {
                  return setShowErrorHelper(true);
                }
                setIsLoading(true);
                addYoutubeVideoHandler(
                  video.id,
                  video.startSeconds,
                  identity.id,
                  identity.fullName || null
                )
                  .then(({ data }) => {
                    const lessonId = data?.docId;
                    setIsLoading(false);
                    handleClose();
                    notify("Added 1 Youtube Video");
                    redirect(`/${FIRESTORE_LESSON_COLLECTION}/${lessonId}`);
                  })
                  .catch((err) => {
                    console.log(err);
                    notify(
                      "Couldn't add the Youtube video. Please try again or contact us: support@cresc.io",
                      "warning"
                    );
                  });
              }}
            >
              <YoutubeIcon />
            </RAButton>
          </Grid>
        </Grid>
      )}
    </ImportDialogWrapper>
  );
};

export default YoutubeImportDialog;
