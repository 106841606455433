import {
  Datagrid,
  List,
  EditButton,
  DeleteButton,
  TextField,
  DateField,
  useGetIdentity,
  Filter,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { ProfileContextProvider } from "../../contexts/ProfileContext";
import LessonListActions from "../../components/LessonListActions";
import QuestBulkActionButtons from "../../components/QuestBulkActionButtons";
import MediaField from "../../components/MediaField";
import CopyableTextField from "../../components/CopyableTextField";
import StatusField from "../../components/StatusField";

const useStyles = makeStyles({
  videoWrapper: {
    maxWidth: "180px",
    width: "160px",
  },
  statsWrapper: {
    maxWidth: "150px",
    width: "100px",
  },
  listActionButton: {
    width: "30px",
    padding: "0",
  },
});

const PostFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="title" alwaysOn />
    </Filter>
  );
};

const ListPage = (props: any) => {
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();
  return identityLoading ? (
    <div></div>
  ) : (
    <ProfileContextProvider>
      <List
        {...props}
        filters={<PostFilter />}
        filter={{
          collectionQuery: (collection: any) =>
            collection.where("createdby", "==", identity?.id),
        }}
        actions={<LessonListActions />}
        bulkActionButtons={<QuestBulkActionButtons />}
        empty={false}
      >
        <Datagrid>
          <MediaField
            label="Video"
            type="source"
            source="rawData"
            videoId="videoId"
            startSeconds="startSeconds"
            endSeconds="endSeconds"
            headerClassName={classes.videoWrapper}
            cellClassName={classes.videoWrapper}
          />
          <TextField source="title" />
          <StatusField
            label="Status"
            source="published"
            headerClassName={classes.statsWrapper}
            cellClassName={classes.statsWrapper}
          />

          <CopyableTextField
            source="dynamicLink"
            label="Shared Link"
            headerClassName={classes.statsWrapper}
            cellClassName={classes.statsWrapper}
          />
          <DateField source="lastupdate" label="Last Update"/>
          <EditButton label="Edit" />
          <DeleteButton label="Delete" />
        </Datagrid>
      </List>
    </ProfileContextProvider>
  );
};

export default ListPage;
