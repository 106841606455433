import React, { useContext, useState, useEffect, useCallback } from "react";
import { useDataProvider, useGetIdentity } from "react-admin";

const DEFAULT_STATE = {
  profile: {} as any,
};
const DEFAULT_FUNCTIONS = {
  updateProfile: (params: object) => {},
  getProfile: () => {},
};

export const ProfileContext = React.createContext({
  ...DEFAULT_STATE,
  ...DEFAULT_FUNCTIONS,
});

export const useProfileContext = () => useContext(ProfileContext);

export function ProfileContextProvider(props: any) {
  const { children, ...rest } = props;
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  const [state, setState] = useState(DEFAULT_STATE);

  const getProfile: typeof DEFAULT_FUNCTIONS.getProfile = useCallback(() => {
    return dataProvider.getUserProfile(identity?.id).then((res: any) => {
      setState({ profile: res?.data });
      console.log("Profile Fetched");
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity]);

  //TODO: Finish function
  const updateProfile: typeof DEFAULT_FUNCTIONS.updateProfile = (progress) =>
    setState((state) => ({ ...state, progress }));

  useEffect(() => {
    if (identity) {
      getProfile();
    }
  }, [getProfile, identity]);

  return (
    <ProfileContext.Provider
      value={{
        ...state,
        ...rest,
        getProfile,
        updateProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
