import { useState, useEffect, useMemo } from "react";
import { AutocompleteArrayInput, useDataProvider } from "react-admin";
import { useFormState } from "react-final-form";
import { instrumentsConfig } from "../../config/params";
import { FIRESTORE_TAG_COLLECTION } from "../../config/firebase";

const AutocompleteTagInput = () => {
  const [tagChoices, setTagChoices] = useState([]);
  const { values } = useFormState();
  const dataProvider = useDataProvider();
  const tagDocId = useMemo(() => {
    if (Array.isArray(values?.instruments) && values.instruments.length >= 1) {
      const instrument = values.instruments[0];
      const instrumentConfig = instrumentsConfig.find(
        (_) => _.id === instrument
      );
      const tagDocId = instrumentConfig?.tags;
      return tagDocId;
    } else {
      return null;
    }
  }, [values.instruments]);
  useEffect(() => {
    if (tagDocId) {
      dataProvider
        .getOne(FIRESTORE_TAG_COLLECTION, {
          id: tagDocId,
        })
        .then(({ data }: { data: any }) => {
          console.log(data);
          setTagChoices(data.tags);
        });
    } else {
      setTagChoices([]);
    }
  }, [dataProvider, tagDocId, values]);

  return (
    <AutocompleteArrayInput
      label="Tags (Instrument specific)"
      fullWidth
      source="tags"
      translateChoice={false}
      optionText="name"
      optionValue="id"
      shouldRenderSuggestions={(val: string) => {
        return val.trim().length > 1;
      }}
      choices={tagChoices}
      onCreate={(newTag: string) =>
        dataProvider.addTag(tagDocId, newTag).then(() => ({id: newTag, name: newTag}))
      }
    />
  );
};

export default AutocompleteTagInput;
