import React from "react";
import { RouteWithoutLayout } from "react-admin";
import { Route } from "react-router";
import InstagramAuth from "./pages/InstagramAuth";
import Profile from "./pages/Profile";
import routes from "./config/routes";

const customRoutes = [
  <RouteWithoutLayout path={routes.instagramAuth} component={InstagramAuth} />,
  <Route path={routes.profile} component={Profile} />,
];

export default customRoutes;
