import React from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";
import Chip from "@material-ui/core/Chip";

const StatusField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  return (
    <Chip
      size="small"
      label={record[source] ? "Published" : "Draft"}
      color={record[source] ? "primary" : "default"}
    />
  );
};

StatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  headerClassName: PropTypes.string,
  cellClassName: PropTypes.string,
};

export default StatusField;
