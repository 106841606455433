import React, { useMemo, useState, useEffect, memo } from "react";
import {
  useGetIdentity,
  useRefresh,
  useListContext,
  useNotify,
  Loading,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import tableIcons from "../../assets/icons/MaterialTableIcons";
import ImportDialogWrapper from "../ImportDialogWrapper";
import { instagramDataImportPipeline } from "../../utils";
import {
  addMetaInfo,
  fsBatchCreate,
  getInstagramQueryUri,
} from "../../utils/firebase";
import { FIRESTORE_LESSON_COLLECTION } from "../../config/firebase";
import { INSTAGRAM_QUERY_PARAMS } from "../../config/constants";

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    width: "100%",
    height: "150px",
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  gridContainer: { width: "100%", flexWrap: "nowrap" },
  mediaWrapper: { width: "150px", height: "150px" },
  mainFeaturedPostContent: {
    width: "100%",
    padding: theme.spacing(2),
  },
}));

const InstagramImportDialog = ({ open, handleClose, token, userId }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const { data: fsData } = useListContext();
  const { identity, loading: identityLoading } = useGetIdentity();
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const importedInstagramPosts = useMemo(() => {
    const fsDataArr = fsData && Object.values(fsData);
    return (
      Array.isArray(fsDataArr) && fsDataArr.map((post) => post.instagram_id)
    );
  }, [fsData]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const queryUrl = getInstagramQueryUri(INSTAGRAM_QUERY_PARAMS, token);
      const response = await fetch(queryUrl);
      const data = await response.json();
      const posts = instagramDataImportPipeline(data.data);
      const postsDisablingDuplicate = Array.isArray(posts)
        ? posts.map((rowData) => {
            rowData.tableData = {
              ...rowData.tableData,
              disabled:
                Array.isArray(importedInstagramPosts) &&
                importedInstagramPosts.includes(rowData.id),
            };
            return rowData;
          })
        : [];
      setIsLoading(false);
      setPosts(postsDisablingDuplicate);
    };
    if (open) fetchData();
  }, [importedInstagramPosts, open, token]);

  return (
    <ImportDialogWrapper
      open={open}
      handleClose={() => {
        setIsLoading(false);
        handleClose();
      }}
    >
      {isLoading && (
        <Loading loadingPrimary="Importing..." loadingSecondary="Please wait" />
      )}
      <MaterialTable
        columns={[
          {
            title: "Video",
            field: "media_url",
            width: 150,
            render: (post) => (
              <video
                className={classes.mediaWrapper}
                poster={post.thumbnail_url}
                preload="none"
                controls
              >
                <source src={post.media_url} type="video/mp4"></source>
              </video>
            ),
          },
          { title: "Caption", field: "caption" },
          { title: "Post Date", field: "timestamp", type: "date", width: 150 },
        ]}
        data={posts}
        title="Select Instagram Posts to Import"
        options={{
          selection: true,
          search: false,
          rowStyle: {
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          },
          selectionProps: (rowData) => ({
            disabled: rowData.tableData.disabled,
            color: "primary",
          }),
        }}
        icons={tableIcons}
        actions={[
          {
            position: "toolbarOnSelect",
            tooltip: "Import selected posts",
            icon: tableIcons.Publish,

            onClick: (event, rowData) => {
              setIsLoading(true);
              console.log(rowData);
              const data = rowData.map((row) =>
                addMetaInfo(row, {
                  source: "instagram",
                  description: row.caption || "",
                  createdby: identity.id,
                  createdbyDisplayName: identity.fullName || null,
                  videoId: row.id,
                  creatorInstagramId: userId,
                  published: false,
                })
              );
              fsBatchCreate(FIRESTORE_LESSON_COLLECTION, data).then(() => {
                refresh();
                handleClose();
                setIsLoading(false);
                notify(
                  `Successfully imported ${rowData.length} Instagram posts.`
                );
              });
              // rowData has all the selected row and I want to redirect to another page with passing those data.
            },
          },
        ]}
      />
    </ImportDialogWrapper>
  );
};

export default memo(InstagramImportDialog);
