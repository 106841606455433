import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
  RAFirebaseOptions,
} from "react-admin-firebase";
import raDataProviderOverrides from "../utils/raDataProviderOverrides";

const config = require("./firebase.js").firebaseConfig;

const options: RAFirebaseOptions = {
  // logging: true,
  associateUsersById: true,
  relativeFilePaths: true,
  useFileNamesInStorage: true,
  // rootRef: "root_collection/some_document",
};
export const dataProvider = raDataProviderOverrides(
  FirebaseDataProvider(config, options)
);
export const authProvider = FirebaseAuthProvider(config, options);
