import React from "react";
import PropTypes from "prop-types";
import { useRecordContext, useNotify } from "react-admin";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@material-ui/core/Typography";
import ShareIcon from "@material-ui/icons/Share";
import IconButton from "@material-ui/core/IconButton";

const CopyableTextField = (props) => {
  const { source } = props;
  const notify = useNotify();
  const record = useRecordContext(props);
  return (
    <CopyToClipboard
      text={record[source]}
      onCopy={() => notify(`Link copied: ${record[source]}`)}
    >
      <Tooltip
        title={
          !record.published
            ? "Publish the phrase to activate"
            : `Copy link: ${record[source]}`
        }
      >
        <IconButton
          aria-label="copy"
          color="primary"
        >
          <ShareIcon />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
};

CopyableTextField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  headerClassName: PropTypes.string,
  cellClassName: PropTypes.string,
};

export default CopyableTextField;
