import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  FileField,
  FileInput,
  required,
  maxLength,
  NumberInput,
  TopToolbar,
  SaveButton,
  Button,
  Toolbar,
  useDataProvider,
  useRedirect,
  useRecordContext,
  useNotify,
} from "react-admin";
import MediaField from "../../components/MediaField";
import AutocompleteTagInput from "../../components/AutocompleteTagInput";
import {
  FIRESTORE_LESSON_COLLECTION,
  FIRESTORE_PHRASE_COLLECTION,
} from "../../config/firebase";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { convertObjectRelativePath, hmsToSeconds } from "../../utils";

const useStyles = makeStyles({
  topToolbar: {
    justifyContent: "flex-start",
  },
  toolbar: {
    width: "100%",
    justifyContent: "flex-start",
  },
  deleteButton: { color: "#f44336" },
});

const PhraseDeleteButton = () => {
  //TODO: Design a way of deleting phrase
  //TODO: Add undoble feature
  const classes = useStyles();
  const { lessonId, id } = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const handleDelete = async (event: any) => {
    //TODO: Add support for deleting from plan schedule or remove the button
    await dataProvider.removePhraseFromLesson(id, lessonId);
    await dataProvider.delete(FIRESTORE_PHRASE_COLLECTION, { id });
    notify("Deleted Phrase");
    redirect(`/${FIRESTORE_LESSON_COLLECTION}/${lessonId}`);
  };
  return (
    <Button
      label="Delete"
      color="primary"
      onClick={handleDelete}
      className={classes.deleteButton}
    >
      <DeleteIcon />
    </Button>
  );
};

const PhraseToolbar = (props: any) => {
  const classes = useStyles();
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton label="Save" redirect={false} submitOnEnter={false} />
      {/* <PhraseDeleteButton /> */}
    </Toolbar>
  );
};

const PostEditActions = ({
  originType,
  originId,
}: {
  originType: string | null;
  originId: string | null;
}) => {
  const classes = useStyles();
  const redirect = useRedirect();
  return (
    <TopToolbar className={classes.topToolbar}>
      {originType && originId && (
        <Button
          label="< Return to Lesson"
          onClick={() => {
            redirect(`/${originType}/${originId}`);
          }}
        />
      )}
    </TopToolbar>
  );
};

const PhraseEdit = (props: any) => {
  const originData = props?.location?.state?.originData;
  const originType = originData?.originType || null;
  const originId = originData?.originId || null;
  return (
    <Edit
      {...props}
      actions={<PostEditActions originType={originType} originId={originId} />}
      mutationMode="pessimistic"
      transform={(_data: any) => {
        const data = convertObjectRelativePath(
          _data,
          FIRESTORE_PHRASE_COLLECTION
        );
        return data;
      }}
    >
      <SimpleForm redirect={false} toolbar={<PhraseToolbar />}>
        {/* <DisabledInput source="id" /> */}
        <TextInput source="videoId" label="Video Id" validate={required()} />
        <MediaField
          label="Video"
          source="rawData"
          type="source"
          videoId="videoId"
          startSeconds="startSeconds"
          endSeconds="endSeconds"
          size="md"
        />
        <TextInput
          source="startSeconds"
          label="Start Seconds"
          validate={required()}
          parse={hmsToSeconds}
        />
        <TextInput
          source="endSeconds"
          label="End Seconds"
          validate={required()}
          parse={hmsToSeconds}
        />
        <div style={{ marginTop: "20px" }} />
        <TextInput
          source="title"
          label="Title"
          validate={maxLength(100)}
          fullWidth
        />
        <SelectInput
          source="difficulty"
          label="Difficulty"
          choices={[
            { id: "beginner", name: "Beginner" },
            { id: "intermediate", name: "Intermediate" },
            { id: "advanced", name: "Advanced" },
          ]}
          fullWidth
        />
        <SelectArrayInput
          label="Instruments"
          source="instruments"
          choices={[
            { id: "acousticGuitar", name: "Acoustic Guitar" },
            { id: "electricGuitar", name: "Electric Guitar" },
          ]}
          fullWidth
        />
        <TextInput
          source="description"
          label="Description"
          multiline
          fullWidth
        />
        <AutocompleteTagInput />
        <FileInput
          source="file"
          label="Tab File (Guitar Pro formats)"
          accept=".gp,.gpx,.gp5,.gp4,.gp3"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export default PhraseEdit;
