// LoginPage.js
import React from "react";
import { Login, LoginForm } from "react-admin";
import { makeStyles, createStyles, Grid, Typography } from "@material-ui/core";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import ForgotPasswordButton from "../../components/CustomForgotPassword";

const useStyles = makeStyles((theme) =>
  createStyles({
    spacer: { padding: theme.spacing(1) },
    legalWrapper: {
      margin: theme.spacing(3),
      lineHeight: "1.5rem",
    },
  })
);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    { provider: "apple.com" },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

const SignInScreen = () => (
  <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
);

const CustomLoginForm = (props) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" className={classes.spacer}>
      <LoginForm {...props} />
      <ForgotPasswordButton {...props} />
      <Grid item className={classes.spacer} />
      <SignInScreen />
      <Typography
        align="center"
        variant="overline"
        color="inherit"
        className={classes.legalWrapper}
      >
        By signing in, you agree to the <br />
        <a href="https://guitarpracticeapp.com/tos/">
          Terms of Service
        </a> and{" "}
        <a href="https://guitarpracticeapp.com/privacy/">Privacy Policy</a>{" "}
      </Typography>
    </Grid>
  );
};

const CustomLoginPage = (props) => (
  <Login {...props}>
    <CustomLoginForm {...props} />
  </Login>
);

export default CustomLoginPage;
