import React, { useState } from "react";
import { Button as RAButton } from "react-admin";
import { Tooltip } from "@material-ui/core";
import YoutubeImportDialog from "../YoutubeImportDialog";
import YoutubeIcon from "../../assets/icons/YoutubeIcon";

const ImportYoutubeButton = () => {
  const [youtubeImportDialogOpen, setYoutubeImportDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title="Import from Instagram">
        <div>
          <RAButton
            color="primary"
            component="span"
            variant="text"
            label="Add Youtube Video"
            onClick={() => setYoutubeImportDialogOpen(true)}
          >
            <YoutubeIcon style={{ fontSize: "24" }} />
          </RAButton>
        </div>
      </Tooltip>
      <YoutubeImportDialog
        open={youtubeImportDialogOpen}
        handleClose={() => setYoutubeImportDialogOpen(false)}
      />
    </>
  );
};

export default ImportYoutubeButton;
