import { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  SelectArrayInput,
  BooleanInput,
  required,
  maxLength,
  TopToolbar,
  ListButton,
  FormGroupContextProvider,
  ImageInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  Datagrid,
  TextField,
  ArrayField,
  Button,
  useGetIdentity,
} from "react-admin";
import { Typography } from "@material-ui/core";
import franc from "franc-min";
import ImportPhraseDialog from "../../components/ImportPhraseDialog";
import AutocompleteTagInput from "../../components/AutocompleteTagInput";
import {
  FIREBASE_DYNAMIC_LINKS_KEY,
  FIREBASE_DYNAMIC_LINKS_REST_API,
  generateDynamicLinks,
  FIRESTORE_PLAN_COLLECTION,
} from "../../config/firebase";
import { get6391LangCode } from "../../config/i18n";
import { instrumentsConfig } from "../../config/params";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/core/styles";
import { convertObjectRelativePath } from "../../utils";

const useStyles = makeStyles({
  topToolbar: {
    justifyContent: "flex-start",
  },
});

const PostEditActions = ({ basePath }: { basePath: any }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.topToolbar}>
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    </TopToolbar>
  );
};

const PlanCreate = (props: any) => {
  const [openImportPhraseDialog, setOpenImportPhraseDialog] = useState({
    isOpen: false,
    originType: "plan",
    originId: null,
    pathInDoc: "",
    originDocData: null,
  });
  const { identity } = useGetIdentity();

  return (
    <>
      <ImportPhraseDialog
        open={openImportPhraseDialog.isOpen}
        handleClose={() =>
          setOpenImportPhraseDialog({
            isOpen: false,
            originType: "plan",
            originId: null,
            pathInDoc: "",
            originDocData: null,
          })
        }
        originId={openImportPhraseDialog.originId}
        originType={FIRESTORE_PLAN_COLLECTION}
        pathInDoc={openImportPhraseDialog.pathInDoc}
        originDocData={openImportPhraseDialog.originDocData}
      />
      <Create
        {...props}
        transform={(_data: any) => {
          const data = convertObjectRelativePath(
            _data,
            FIRESTORE_PLAN_COLLECTION
          );
          const descLang6393 = franc(data.description);
          const descLang6391 = descLang6393 && get6391LangCode(descLang6393);
          data.createdbyDisplayName = identity?.fullName || null;
          data.descLang = descLang6391 || null;
          return fetchDynamicLinks(FIRESTORE_PLAN_COLLECTION, data).then(
            (res: any) =>
              res?.shortLink ? { ...data, dynamicLink: res?.shortLink } : data
          );
        }}
        actions={<PostEditActions basePath={`/${FIRESTORE_PLAN_COLLECTION}`} />}
        mutationMode="pessimistic"
      >
        <SimpleForm redirect={false}>
          {/* <DisabledInput source="id" /> */}
          <FormGroupContextProvider name="overview">
            <BooleanInput label="Publish" source="published" />
            <ImageInput
              accept=".png, .jpg, .jpeg"
              source="thumbnailFile"
              label="Thumbnail"
              maxSize="1000000"
            >
              <ImageField source="src" title="title"/>
            </ImageInput>
            <div style={{ marginTop: "20px" }} />
            <TextInput
              source="title"
              label="Title"
              validate={maxLength(100)}
              fullWidth
            />
            <SelectInput
              source="difficulty"
              label="Difficulty"
              choices={[
                { id: "beginner", name: "Beginner" },
                { id: "intermediate", name: "Intermediate" },
                { id: "advanced", name: "Advanced" },
              ]}
              fullWidth
            />
            <NumberInput
              source="durationPerDay"
              label="Practice duration per day (in Mins)"
              min={0}
              fullWidth
            />
            <SelectArrayInput
              label="Instruments"
              source="instruments"
              choices={instrumentsConfig}
              fullWidth
            />
            <TextInput
              source="description"
              label="Description"
              multiline
              fullWidth
              validate={required()}
            />
            <AutocompleteTagInput />
          </FormGroupContextProvider>
          <ArrayInput source="schedule">
            <SimpleFormIterator>
              <FormDataConsumer>
                {({
                  getSource,
                  scopedFormData,
                  formData,
                  id,
                }: {
                  getSource: Function;
                  formData: any;
                  scopedFormData: any;
                  id: string;
                }) => {
                  const _ = id.match(/\[\d+\]/) || [];
                  const day = parseInt(_[0].substring(1, _[0].length - 1)) + 1;
                  return (
                    <>
                      <div>
                        <Typography
                          align="left"
                          variant="overline"
                          color="inherit"
                        >
                          {`Day ${day}`}
                        </Typography>
                      </div>
                      <ArrayField source="phrases" record={scopedFormData}>
                        <Datagrid>
                          <TextField source="title" />
                          <TextField source="topic" />
                          <TextField source="duration" />
                        </Datagrid>
                      </ArrayField>
                      <Button
                        label="Import phrase"
                        onClick={() =>
                          setOpenImportPhraseDialog({
                            isOpen: true,
                            originType: "plan",
                            originId: formData.id,
                            pathInDoc: getSource("phrases"),
                            originDocData: formData,
                          })
                        }
                      ></Button>
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </>
  );
};

const fetchDynamicLinks = async (contentType: string, data: any) => {
  const dynamicLinkMetaData = await generateDynamicLinks(contentType, data);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dynamicLinkMetaData),
  };
  const dynamicLink = await fetch(
    `${FIREBASE_DYNAMIC_LINKS_REST_API}${FIREBASE_DYNAMIC_LINKS_KEY}`,
    options
  );
  const dynamicLinkRes = await dynamicLink.json();
  console.log(dynamicLinkRes);
  return dynamicLinkRes;
};

export default PlanCreate;
