//Cloud functions
import { functions } from "../config/firebase";
import { db } from "../config/firebase";

export enum CLOUD_FUNCTIONS {
  instagramAuthHandler = "instagramAuthHandler",
  addYoutubeVideo = "addYoutubeVideo",
}

export const cloudFunction = (
  name: string,
  input: any,
  success?: Function,
  fail?: Function
) =>
  new Promise((resolve, reject) => {
    const callable = functions.httpsCallable(name);
    callable(input)
      .then((result: any) => {
        if (success) {
          resolve(success(result));
        }
      })
      .catch((error: any) => {
        if (fail) {
          reject(fail(error));
        }
      });
  });

export const instagramAuthHandler = (code: string) =>
  functions.httpsCallable(CLOUD_FUNCTIONS.instagramAuthHandler)({ code });

export const addYoutubeVideoHandler = (
  videoId: string,
  startSeconds: number | null,
  createdby: string,
  createdbyDisplayName: string
) =>
  functions.httpsCallable(CLOUD_FUNCTIONS.addYoutubeVideo)({
    videoId,
    startSeconds,
    createdby,
    createdbyDisplayName,
  });

//Utils for Firestore
export const fsBatchCreate = (collection: string, data: Array<any>) => {
  let batch = db.batch();
  data.forEach((docData) => {
    const docRef = db.collection(collection).doc();
    batch.set(docRef, { ...docData, id: docRef.id });
  });
  return batch.commit();
};

export const addMetaInfo = (data: object, info: object) => ({
  rawData: data,
  ...info,
});

export const getInstagramQueryUri = (query: string, token: string) => {
  return `https://graph.instagram.com/me/media?fields=${query}&access_token=${token}`;
};
