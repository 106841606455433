import firebase, {
  fbStorage,
  db,
  FIRESTORE_USER_COLLECTION,
  FIRESTORE_LESSON_COLLECTION,
  FIRESTORE_PLAN_COLLECTION,
  FIRESTORE_PHRASE_COLLECTION,
  FIRESTORE_TAG_COLLECTION,
} from "../config/firebase";
import { defaultPracticeMins } from "../config/params";
import set from "lodash.set";
import get from "lodash.get";
const raDataProviderOverrides = (dataProvider) => {
  return {
    ...dataProvider,
    getUserProfile(uid) {
      return dataProvider.getOne(FIRESTORE_USER_COLLECTION, { id: uid });
    },
    async updateUserProfile({ data }) {
      // Convert a newly uploaded file to b64
      let avatar = typeof data.avatar === "string" ? data.avatar : null;
      if (data.avatar?.rawFile instanceof File) {
        const ext = data.avatar?.rawFile.path.split(".")[1];
        if (!ext) {
          console.log("Avatar upload: can not get image type");
          return Promise.resolve({ data });
        }
        await fbStorage
          .ref(`users/${data.id}/profileImage.${ext}`)
          .put(data.avatar.rawFile)
          .then(async (snapshot) => {
            await snapshot.ref
              .getDownloadURL()
              .then((value) => {
                avatar = value;
                data.avatar = value;
              })
              .catch((err) => {
                console.log("Avatar upload: " + err);
              });
          });
      }

      await firebase.auth().currentUser.updateProfile({
        displayName: data.fullName,
        photoURL: avatar,
      });
      await db.collection(FIRESTORE_USER_COLLECTION).doc(data.id).update({
        displayName: data.fullName,
        fullName: data.fullName,
        photoURL: avatar,
        avatar,
      });

      return Promise.resolve({ data });
    },
    async addPhraseToLesson({ phraseId, lessonId }) {
      await db
        .collection(FIRESTORE_LESSON_COLLECTION)
        .doc(lessonId)
        .update({
          phrases: firebase.firestore.FieldValue.arrayUnion(phraseId),
        });
      await db
        .collection(FIRESTORE_PHRASE_COLLECTION)
        .doc(phraseId)
        .update({
          lessonIds: firebase.firestore.FieldValue.arrayUnion(lessonId),
        });
      return Promise.resolve({ data: { isDone: true } });
    },
    //As the plan schedule is a nested array, the update has rewrite the entire doc
    async addPhraseToPlanSchedule({
      phraseId,
      topic,
      practiceMins,
      planId,
      originalPlanData,
      schedulePath,
    }) {
      const tempDocData = JSON.parse(JSON.stringify(originalPlanData));
      const curPhrases = get(tempDocData, schedulePath) || [];
      if (curPhrases.some((phrase) => phrase?.phraseId === phraseId)) {
        return Promise.resolve({ data: { isDone: true } });
      }
      const phraseDoc = await dataProvider.getOne(FIRESTORE_PHRASE_COLLECTION, {
        id: phraseId,
      });
      if (!phraseDoc?.data?.id || !phraseDoc?.data?.title)
        return Promise.resolve({
          data: {
            isDone: false,
            error: "Invalid phrase. Please double check phrase ID.",
          },
        });
      const title = phraseDoc?.data?.title;
      const duration = practiceMins || defaultPracticeMins;
      const newArr = [...curPhrases, { phraseId, title, topic, duration }];
      // Update new phrase
      let newPlanData = set(tempDocData, schedulePath, newArr);
      // Update meta data
      const scheduleMetaPath = schedulePath.substring(
        0,
        schedulePath.lastIndexOf(".")
      );
      let totalDuration = get(tempDocData, `${scheduleMetaPath}.duration`) || 0;
      totalDuration += duration;
      let totalCount = get(tempDocData, `${scheduleMetaPath}.count`) || 0;
      totalCount++;
      newPlanData = set(
        newPlanData,
        `${scheduleMetaPath}.duration`,
        totalDuration
      );
      newPlanData = set(newPlanData, `${scheduleMetaPath}.count`, totalCount);
      await db
        .collection(FIRESTORE_PLAN_COLLECTION)
        .doc(planId)
        .update(newPlanData);
      return Promise.resolve({ data: { isDone: true } });
    },
    //TODO: Add support for removing from plan schedule
    async removePhraseFromLesson(phraseId, lessonId) {
      await db
        .collection(FIRESTORE_LESSON_COLLECTION)
        .doc(lessonId)
        .update({
          // phrases: [phraseId],
          phrases: firebase.firestore.FieldValue.arrayRemove(phraseId),
        });
      return Promise.resolve({ data: { isDone: true } });
    },
    async addTag(instrument, tag) {
      await db
        .collection(FIRESTORE_TAG_COLLECTION)
        .doc(instrument)
        .update({
          tags: firebase.firestore.FieldValue.arrayUnion({
            id: tag,
            name: tag,
          }),
        });
      return Promise.resolve({ data: { isDone: true } });
    },
  };
};

export default raDataProviderOverrides;
