import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import {
  useDataProvider,
  useNotify,
  useGetIdentity,
  ImageInput,
  TextInput,
  SimpleForm,
  required,
  SaveContextProvider,
  ImageField,
  Toolbar,
  SaveButton,
} from "react-admin";

const ProfileContext = createContext();
export const ProfileProvider = ({ children }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1),
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ProfileEdit = (props: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { refreshProfile } = useProfile();
  const [saving, setSaving] = useState();
  const { loaded, identity } = useGetIdentity();
  const handleSave = useCallback(
    (values) => {
      setSaving(true);
      dataProvider.updateUserProfile(
        { data: values },
        {
          onSuccess: ({ data }) => {
            setSaving(false);
            notify("Your profile has been updated", "info", {
              _: "Your profile has been updated",
            });
            refreshProfile();
          },
          onFailure: onFailure,
        }
      );
    },
    [dataProvider, refreshProfile, notify]
  );
  const onFailure = () => {
    setSaving(false);
    notify(
      "A technical error occured while updating your profile. Please try later.",
      "warning",
      {
        _:
          "A technical error occured while updating your profile. Please try later.",
      }
    );
  };
  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
      setOnFailure: onFailure,
    }),
    [saving, handleSave]
  );
  if (!loaded) {
    return null;
  }
  const formatLogo = (value) => {
    if (!value || typeof value === "string") {
      // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
      return { url: value };
    } else {
      // Else a new image is selected which results in a value object already having a preview link under the url key
      return value;
    }
  };

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        save={handleSave}
        toolbar={<CustomToolbar />}
        record={identity ? identity : { fullName: "", avatar: "" }}
      >
        <TextInput source="fullName" validate={required()} />
        <ImageInput
          accept=".png, .jpg, .jpeg"
          format={formatLogo}
          source="avatar"
          label="Profile Image"
          maxSize="1000000"
        >
          <ImageField />
        </ImageInput>
      </SimpleForm>
    </SaveContextProvider>
  );
};

export default ProfileEdit;
