import React from "react";
import { Fragment } from "react";
import { BulkDeleteButton } from "react-admin";
// import BulkPublishButton from "../BulkPublishButton";

const QuestBulkActionButtons = (props) => (
  <Fragment>
    {/* <BulkPublishButton label="Publish" {...props} /> */}
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} undoable={false} />
  </Fragment>
);

export default QuestBulkActionButtons;
