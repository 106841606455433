import { cloneElement } from "react";
import { useListContext, TopToolbar, sanitizeListRestProps } from "react-admin";
import ImportInstagramButton from "../ImportInstagramButton";
import ImportYoutubeButton from "../ImportYoutubeButton";

const LessonListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* Add your custom actions */}
      <ImportInstagramButton />
      <ImportYoutubeButton />
    </TopToolbar>
  );
};

export default LessonListActions;
