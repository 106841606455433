import React from "react";
import { Admin, Resource } from "react-admin";
import { createBrowserHistory as createHistory } from "history";
import LessonList from "./pages/LessonList";
import LessonEdit from "./pages/LessonEdit";
import PlanList from "./pages/PlanList";
import PlanCreate from "./pages/PlanCreate";
import PlanEdit from "./pages/PlanEdit";
import PhraseEdit from "./pages/PhraseEdit";
import PhraseCreate from "./pages/PhraseCreate";
import CustomLoginPage from "./pages/CustomLoginPage";
import CustomLayout from "./components/CustomLayout";
import {
  FIRESTORE_LESSON_COLLECTION,
  FIRESTORE_PHRASE_COLLECTION,
  FIRESTORE_PLAN_COLLECTION,
  FIRESTORE_TAG_COLLECTION,
  FIRESTORE_USER_COLLECTION,
} from "./config/firebase";
import { dataProvider, authProvider } from "./config/ra";
import customRoutes from "./customRoutes";

const history = createHistory();

class App extends React.Component {
  render() {
    return (
      <Admin
        layout={CustomLayout}
        loginPage={CustomLoginPage}
        customRoutes={customRoutes}
        dataProvider={dataProvider}
        authProvider={authProvider}
        history={history}
      >
        <Resource
          name={FIRESTORE_LESSON_COLLECTION}
          list={LessonList}
          edit={LessonEdit}
        />
        <Resource
          name={FIRESTORE_PLAN_COLLECTION}
          list={PlanList}
          create={PlanCreate}
          edit={PlanEdit}
        />
        <Resource
          name={FIRESTORE_PHRASE_COLLECTION}
          edit={PhraseEdit}
          create={PhraseCreate}
        />
        <Resource name={FIRESTORE_TAG_COLLECTION} />
        <Resource name={FIRESTORE_USER_COLLECTION} />
      </Admin>
    );
  }
}

export default App;
